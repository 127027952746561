import { Image, Link } from '@chakra-ui/react';

import LogoImage from '../resources/assets/logo.png';
import RouteIds from '../helpers/RouteIds';

const Logo = ({ href }) => (
  <Link href={href || RouteIds.Welcome}>
    <Image
      w='60px'
      h='60px'
      src={LogoImage}
    />
  </Link>
);

export default Logo;