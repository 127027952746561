import { extendTheme } from '@chakra-ui/react';

export const Colors = {
  primary: 'brand.primary',
  secondary: 'brand.secondary',
};

export const TextStyle = {
  h1: 'h1',
  h1Bold: 'h1Bold',
  h2: 'h2',
  h2Bold: 'h2',
  h3: 'h3',
  h4: 'h4',
  h4Bold: 'h4Bold',
  h5: 'h5',
  h5Bold: 'h5Bold',
  body: 'body',
  bodyBold: 'bodyBold',
  footnote: 'footnote',
  footnoteBold: 'footnoteBold',
  caption1: 'caption1',
  caption1Bold: 'caption1Bold',
  caption2: 'caption2',
};

export const customTheme = extendTheme({
  colors: {
    [Colors.secondary]: '#000000',
    [Colors.primary]: '#FFFFFF',
  },
  textStyles: {
    [TextStyle.h1]: {
      fontSize: '3.052rem',
      lineHeight: 'normal'
    },
    [TextStyle.h1Bold]: {
      fontWeight: 'bold',
      fontSize: '3.052rem',
      lineHeight: 'normal'
    },
    [TextStyle.h2]: {
      fontSize: '2.441rem',
      lineHeight: 'normal'
    },
    [TextStyle.h2Bold]: {
      fontSize: '2.441rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    },
    [TextStyle.h3]: {
      fontSize: '1.953rem',
      lineHeight: 'normal'
    },
    [TextStyle.h4]: {
      fontSize: '1.563rem',
      lineHeight: 'normal'
    },
    [TextStyle.h4Bold]: {
      fontSize: '1.563rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    },
    [TextStyle.h5]: {
      fontSize: '1.25rem',
      lineHeight: 'normal'
    },
    [TextStyle.h5Bold]: {
      fontSize: '1.25rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    },
    [TextStyle.body]: {
      fontSize: '1rem',
      lineHeight: 'normal'
    },
    [TextStyle.bodyBold]: {
      fontSize: '1rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    },
    [TextStyle.footnote]: {
      fontSize: '0.8rem',
      lineHeight: 'normal'
    },
    [TextStyle.footnoteBold]: {
      fontSize: '0.8rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    },
    [TextStyle.caption1]: {
      fontSize: '0.64rem',
      lineHeight: 'normal'
    },
    [TextStyle.caption1Bold]: {
      fontWeight: 'bold',
      fontSize: '0.64rem',
      lineHeight: 'normal'
    },
    [TextStyle.caption2]: {
      fontSize: '0.512rem',
      lineHeight: 'normal'
    },
  }
});