import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import RouteIds from './helpers/RouteIds';
import Welcome from './routes/Welcome';
import Privacy from './routes/Privacy';
import Terms from './routes/Terms';
import { customTheme } from './theme';
import i18n from './helpers/i18n';
import Post from './routes/Post';

const InsideRedux = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={RouteIds.Welcome} element={<Welcome />} />
          <Route path={RouteIds.Privacy} element={<Privacy />} />
          <Route path={RouteIds.Terms} element={<Terms />} />
          <Route path={RouteIds.Post} element={<Post />} />
          <Route path='/*' element={<Navigate replace to={RouteIds.Welcome} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

function App() {
  return (
      // <Provider store={store}>
      <ChakraProvider theme={customTheme}>
        <InsideRedux />
      </ChakraProvider>
      // </Provider>
  );
}

export default App;
