import Logo from './Logo';
import { Flex } from '@chakra-ui/react';

const TopBar = () => {
  return (
    <Flex
      direction='row-reverse'
      as='nav'
      px={4}
      py={3}
      alignItems='center'
      justify='center'
    >
      <Logo />
    </Flex>
  );
};

export default TopBar;