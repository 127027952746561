import { Box, Center, Flex, HStack, Icon, Image, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { BiRepost } from 'react-icons/bi';
import { IoIosShareAlt } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import DefaultLayout from '../../components/DefaultLayout';
import { Colors, TextStyle } from '../../theme';
import api from '../../services/api';
import { toast } from '../../components/Toast';

const PostItem = ({ post }) => {
  const { t } = useTranslation();

  const showAlertiOS = () => {
    alert(t('post.alert.downloadiOSApp'));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: t('post.share.linkWasCopied'),
      status: 'success',
      duration: 3000
    });
  };

  if (post.isLoading) {
    return (
      <Spinner
        color={Colors.primary}
        size='md'
      />
    );
  }
  if (post.hasError) {
    return (
      <Text
        textStyle={TextStyle.body}
        color={Colors.primary}
      >
        { t('post.errors.notFound') }
      </Text>
    );
  }
  return (
    <VStack spacing={8}>
      <VStack
        spacing={2}
        maxWidth='400px'
        w='100%'
      >
        <Box
          aspectRatio={0.75}
          maxW='100%'
          position='relative'
        >
          <Image
            h='100%'
            w='100%'
            src={post.imageUrl}
          />
          <Center
            position='absolute'
            left={0}
            right={0}
            bottom={4}
          >
            <Center
              px={4}
              py={1}
              backgroundColor={Colors.primary}
              cursor='pointer'
              onClick={showAlertiOS}
              borderRadius='10%/50%'
            >
              <Text
                color={Colors.secondary}
                textStyle={TextStyle.h4}
              >
                { t('post.copyStyle').toUpperCase() }
              </Text>
            </Center>
          </Center>
        </Box>
        {
          post.caption && post.caption.length > 0 && (
            <Text
              w='100%'
              textStyle={TextStyle.h5}
              color={Colors.primary}
            >
              <Text
                textStyle={TextStyle.h5Bold}
                color={Colors.primary}
                as='span'
              >
                { post.author.username + " " }
              </Text>
              { post.caption }
            </Text>
          )
        }
        <Flex direction='row' justifyContent='space-between' w='100%'>
          <HStack spacing={1} cursor='pointer' onClick={showAlertiOS}>
            <Icon
              as={BiRepost}
              color={Colors.primary}
              w={6}
              h={6}
            />
            <Text
              textStyle={TextStyle.bodyBold}
              color={Colors.primary}
            >
              { post.usageCount }
            </Text>
          </HStack>
          <Icon
            as={IoIosShareAlt}
            w={6}
            h={6}
            color={Colors.primary}
            cursor='pointer'
            onClick={copyLink}
          />
        </Flex>
      </VStack>
      <Link href='https://apps.apple.com/us/app/surreal-ai-photo-art-generator/id6476086032'>
        <Image
          w='164px'
          h='56px'
          src='/appstore.svg'
          alt='App Store'
        />
      </Link>
    </VStack>
  );
};

const Post = () => {
  const { postId } = useParams();

  const [post, setPost] = useState({ isLoading: true });

  useEffect(() => {
    if (postId === undefined) {
      return;
    }
    const fetchData = async () => {
      try {
        const { data } = await api.get(`/v1/post/${postId}`);
        setPost({ ...data, isLoading: false });
      } catch (error) {
        console.log(error);
        setPost({ isLoading: false, hasError: true });
      }
    };

    fetchData();
  }, [postId]);

  return (
    <DefaultLayout>
      <Flex w='100%' justifyContent='center' alignItems='center' flexGrow={1}>
        <PostItem post={post} />
      </Flex>
    </DefaultLayout>
  )
}

export default Post;