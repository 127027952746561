import { Flex, Image, Link, Text, VStack, keyframes } from '@chakra-ui/react';
import DefaultLayout from '../../components/DefaultLayout';
import { Colors, TextStyle } from '../../theme';
import { useTranslation } from 'react-i18next';
import LogoImage from '../../resources/assets/logo.png';

const spin = keyframes`  
  from {transform: rotate(0deg);}   
  to {transform: rotate(360deg)} 
`;


const Welcome = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Flex w='100%' justifyContent='center' alignItems='center' flexGrow={1}>
        <VStack spacing='40px' flexGrow={1}>
          <Image
            w='400px'
            aspectRatio={1}
            maxW='100%'
            src={LogoImage}
            animation={`${spin} infinite 20s linear`}
          />
          <Text
            textAlign='center'
            textColor={Colors.primary}
            textStyle={TextStyle.h3}
            mt='-100px'
          >
            { t('welcome.title') }
          </Text>
          <Link href='https://apps.apple.com/us/app/surreal-ai-photo-art-generator/id6476086032'>
            <Image
              w='164px'
              h='56px'
              src='/appstore.svg'
              alt='App Store'
            />
          </Link>
        </VStack>
      </Flex>
    </DefaultLayout>
  )
}

export default Welcome;