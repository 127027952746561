import { Flex } from '@chakra-ui/react';
import { Colors } from '../theme';
import { defaultScreenPadding, maxWidth } from '../helpers/utils';
import Footer from './Footer';
import TopBar from './TopBar';

const DefaultLayout = ({ children, backgroundColor }) => (
  <Flex
    backgroundColor={backgroundColor || Colors.secondary}
    minH='100vh'
    direction='column'
  >
    <TopBar />
    <Flex
      grow={1}
      maxW={maxWidth}
      px={defaultScreenPadding}
      direction='column'
      alignSelf='center'
      w='100%'
    >
      <Flex
        w='100%'
        py={4}
        direction='column'
        grow={1}
      >
        { children }
      </Flex>
    </Flex>
    <Footer />
  </Flex>
);

export default DefaultLayout;