import axios from 'axios';
import vars from '../config/vars';

let url = 'https://surreals.app';
if (vars.IS_DEV) {
  url = 'http://localhost:4000';
  // url = 'http://192.168.0.17:4000';
}

export const BASE_URL = url;
export const BASE_URL_API = `${BASE_URL}/api`;

const axiosInstance = axios.create({
  baseURL: BASE_URL_API,
});

export default axiosInstance;
